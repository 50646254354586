<template>
  <div class="pointer-events-auto md:hidden">
    <Popover v-slot="{ close }">
      <PopoverButton class="rounded-full bg-white/90 py-3 px-5 inline-flex items-center justify-center shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20">
        Menu
        <icon :icon="faChevronDown" class="ml-2 text-zinc-500 dark:text-zinc-400 text-xs mt-1" />
      </PopoverButton>
      <PopoverOverlay class="fixed inset-0 z-50 bg-zinc-800/40 backdrop-blur-sm dark:bg-black/80" />
      <PopoverPanel class="fixed inset-x-4 top-8 z-50 origin-top rounded-3xl bg-white p-8 ring-1 ring-zinc-900/5 dark:bg-zinc-900 dark:ring-zinc-800">
        <div class="flex items-center justify-between">
          <Title class="text-sm font-medium text-zinc-600 dark:text-zinc-400" text="Navigation" />
          <BaseButton class="-m-1 p-1" @click.prevent="close">
            <icon :icon="faTimes" class="text-zinc-500 dark:text-zinc-400" />
          </BaseButton>
        </div>
        <nav class="mt-6">
          <ul class="-my-2 divide-y divide-zinc-100 text-base text-zinc-800 dark:divide-zinc-100/5 dark:text-zinc-300">
            <li>
              <router-link
                :to="{name: RouteNames.home }"
                v-slot="{ isActive, href, navigate }"
                custom
              >
                <PopoverButton
                  :as="Link"
                  :href="href"
                  class="block py-2"
                  :class="{
                    'text-pink-500 dark:text-pink-400': isActive,
                  }"
                  @click.prevent="handleNavigation(navigate, close)"
                >
                  <Text as="span" text="Home" />
                </PopoverButton>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{name: RouteNames.about }"
                v-slot="{ isActive, href, navigate }"
                custom
              >
                <PopoverButton
                  :as="Link"
                  :href="href"
                  class="block py-2"
                  :class="{
                    'text-pink-500 dark:text-pink-400': isActive,
                  }"
                  @click.prevent="handleNavigation(navigate, close)"
                >
                  <Text as="span" text="About" />
                </PopoverButton>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{name: RouteNames.articles }"
                v-slot="{ href, navigate }"
                custom
              >
                <PopoverButton
                  :as="Link"
                  :href="href"
                  class="block py-2"
                  :class="{
                    'text-pink-500 dark:text-pink-400': isActiveArticles,
                  }"
                  @click.prevent="handleNavigation(navigate, close)"
                >
                  <Text as="span" text="Articles" />
                </PopoverButton>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{name: RouteNames.projects }"
                v-slot="{ isActive, href, navigate }"
                custom
              >
                <PopoverButton
                  :as="Link"
                  :href="href"
                  class="block py-2"
                  :class="{
                    'text-pink-500 dark:text-pink-400': isActive,
                  }"
                  @click.prevent="handleNavigation(navigate, close)"
                >
                  <Text as="span" text="Projects" />
                </PopoverButton>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{name: RouteNames.uses }"
                v-slot="{ isActive, href, navigate }"
                custom
              >
                <PopoverButton
                  :as="Link"
                  :href="href"
                  class="block py-2"
                  :class="{
                    'text-pink-500 dark:text-pink-400': isActive,
                  }"
                  @click.prevent="handleNavigation(navigate, close)"
                >
                  <Text as="span" text="Uses" />
                </PopoverButton>
              </router-link>
            </li>
          </ul>
        </nav>
      </PopoverPanel>
    </Popover>
  </div>
  <nav class="pointer-events-auto hidden md:block">
    <ul class="flex rounded-full bg-white/90 px-3 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10">
      <li>
        <router-link
          :to="{name: RouteNames.home }"
          v-slot="{ isActive, href, navigate }"
          custom
        >
          <Link
            :href="href"
            @click="navigate"
            class="relative block group px-3 py-2 transition hover:text-pink-500 dark:hover:text-pink-400"
            :class="{
              'text-pink-500 dark:text-pink-400': isActive,
            }"
          >
            <Text as="span" text="Home" />
            <GradientBottom v-if="isActive" />
          </Link>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name: RouteNames.about }"
          v-slot="{ isActive, href, navigate }"
          custom
        >
          <Link
            :href="href"
            @click="navigate"
            class="relative block group px-3 py-2 transition hover:text-pink-500 dark:hover:text-pink-400"
            :class="{
              'text-pink-500 dark:text-pink-400': isActive,
            }"
          >
            <Text as="span" text="About" />
            <GradientBottom v-if="isActive" />
          </Link>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name: RouteNames.articles }"
          v-slot="{ href, navigate }"
          custom
        >
          <Link
            :href="href"
            @click="navigate"
            class="relative block group px-3 py-2 transition hover:text-pink-500 dark:hover:text-pink-400"
            :class="{
              'text-pink-500 dark:text-pink-400': isActiveArticles,
            }"
          >
            <Text as="span" text="Articles" />
            <GradientBottom v-if="isActiveArticles" />
          </Link>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name: RouteNames.projects }"
          v-slot="{ isActive, href, navigate }"
          custom
        >
          <Link
            :href="href"
            @click="navigate"
            class="relative block group px-3 py-2 transition hover:text-pink-500 dark:hover:text-pink-400"
            :class="{
              'text-pink-500 dark:text-pink-400': isActive,
            }"
          >
            <Text as="span" text="Projects" />
            <GradientBottom v-if="isActive" />
          </Link>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name: RouteNames.uses }"
          v-slot="{ isActive, href, navigate }"
          custom
        >
          <Link
            :href="href"
            @click="navigate"
            class="relative block group px-3 py-2 transition hover:text-pink-500 dark:hover:text-pink-400"
            :class="{
              'text-pink-500 dark:text-pink-400': isActive,
            }"
          >
            <Text as="span" text="Uses" />
            <GradientBottom v-if="isActive" />
          </Link>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { Popover, PopoverButton, PopoverOverlay, PopoverPanel } from '@headlessui/vue';
import { faChevronDown, faTimes } from '@fortawesome/sharp-regular-svg-icons';
import { RouteNames } from '@/config';
import GradientBottom from '@/components/atoms/GradientBottom.vue';
import Link from '@/components/atoms/Link.vue';
import Text from '@/components/atoms/Text.vue';
import Title from '@/components/atoms/Title.vue';
import BaseButton from '@/components/atoms/BaseButton.vue';

const route = useRoute();

const isActiveArticles = computed(() => {
  // Check if the current route is 'articles' or starts with 'articles/' (for child routes)
  return route.path === '/articles' || route.path.startsWith('/articles/');
});

const handleNavigation = (navigate: () => void, close: () => void) => {
  navigate();
  close();
};
</script>
