<template>
  <BaseButton
    @click.prevent="toggleTheme"
    class="absolute top-0 right-10 rounded-full bg-white/90 w-12 h-12 md:w-8 md:h-8 inline-flex items-center justify-center shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20"
  >
    <icon :icon="faBrightness" class="hidden text-zinc-500 transition dark:block" />
    <icon :icon="faMoon" class="block text-zinc-400 transition dark:hidden" />
  </BaseButton>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { faBrightness, faMoon } from '@fortawesome/sharp-regular-svg-icons';
import BaseButton from '@/components/atoms/BaseButton.vue';

const darkTheme = ref(false);

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  document.documentElement.classList.add('dark');
  darkTheme.value = true;
} else {
  document.documentElement.classList.remove('dark');
}

const toggleTheme = () => {
  darkTheme.value = !darkTheme.value;
  document.documentElement.classList.toggle('dark', darkTheme.value);
}
</script>