<template>
  <footer class="border-t border-zinc-100 mt-32 pb-16 pt-10 dark:border-zinc-700/40">
    <div class="flex flex-col items-center justify-between gap-6 sm:flex-row px-2 lg:px-0">
      <FooterNavigation />
      <Text
        :text="`© ${year} TripleBits. All rights reserved.`"
        class="text-zinc-500 dark:text-zinc-400"
      />
    </div>
  </footer>
</template>

<script setup lang="ts">
import FooterNavigation from '@/components/molecules/FooterNavigation.vue';
import Text from '@/components/atoms/Text.vue';

const year = new Date().getFullYear();
</script>
