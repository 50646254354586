import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { RouteNames } from '@/config/index';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: RouteNames.home,
    component: () => import('@/components/screens/Home.vue'),
  },
  {
    path: '/about',
    name: RouteNames.about,
    component: () => import('@/components/screens/About.vue'),
  },
  {
    path: '/articles',
    name: RouteNames.articles,
    component: () => import('@/components/screens/Articles.vue'),
  },
  {
    path: '/articles/:slug',
    name: RouteNames.article,
    component: () => import('@/components/screens/Article.vue'),
  },
  {
    path: '/projects',
    name: RouteNames.projects,
    component: () => import('@/components/screens/Projects.vue'),
  },
  {
    path: '/uses',
    name: RouteNames.uses,
    component: () => import('@/components/screens/Uses.vue'),
  },
  {
    path: '/404',
    name: RouteNames.notFound,
    component: () => import('@/components/screens/NotFound.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: RouteNames.catchAll,
    redirect: { name: RouteNames.notFound },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash };
    }
  },
});

export default router;
