
export const APIUrl = import.meta.env.VITE_API_URL as string;

export const RouteNames = {
  root: 'root',
  home: 'home',
  about: 'about',
  articles: 'articles',
  article: 'article',
  projects: 'projects',
  uses: 'uses',
  cookies: 'cookies',
  notFound: 'not-found',
  catchAll: 'catch-all',
};
