import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createHead } from '@unhead/vue';
import router from '@/config/router';
import App from './App.vue';

const head = createHead();
const app = createApp(App)
  .use(head)
  .use(router)
  .component('icon', FontAwesomeIcon)
;

app.mount('#app');
